(() => {
  const filterNullValuesFromObject = object => Object.fromEntries(Object.entries(object).filter(([_, v]) => v != null && v !== '' && typeof v !== 'undefined'));

  const mapProperties = object => ({
    company_id: 'rollworks_' + (object.domain || object.company_name),
    rollworks_domain: object.domain,
    rollworks_company_name: object.company_name,
    rollworks_company_industry: object.company_industry,
    rollworks_company_revenue: object.company_revenue,
    rollworks_company_size: object.company_size,
    rollworks_account_lists: object.account_lists,
    rollworks_journey_stage: object.journey_stage
  });

  const processIdentify = object => {
    const identityObject = filterNullValuesFromObject(mapProperties(object));
    if (!identityObject.rollworks_domain && !identityObject.rollworks_company_name) return;
    window.HockeyStack.identify(identityObject);
    window.HockeyStack.addSharedProperty({
      key: 'company_id',
      value: 'rollworks_' + (identityObject.rollworks_domain || identityObject.rollworks_company_name),
      properties: {
        company_id: 'rollworks_' + (identityObject.rollworks_domain || identityObject.rollworks_company_name),
        company_name: identityObject.rollworks_company_name,
        company_domain: identityObject.rollworks_domain,
        company_industry: identityObject.rollworks_company_industry,
        company_number_of_employees: identityObject.rollworks_company_size,
        company_annual_revenue: identityObject.rollworks_company_revenue,
        is_crm_object: false
      }
    });
  }

  const getData = () => {
    if (!(timeout > 5000 || window.__adroll_loaded)) {
      timeout += 50;
      setTimeout(getData, 50);
      return;
    }

    window.onB2BPersonalizationDataReady = (function (personalizationData) {
      try {

        if (window.HockeyStack) return processIdentify(personalizationData);

        if (!Array.isArray(window.hockeystackQueue)) {
          window.hockeystackQueue = [];
        }
        window.hockeystackQueue.push(() => processIdentify(personalizationData));
      } catch (error) {
      }
    });
  }

  let timeout = 0;
  getData();
})();

